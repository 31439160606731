import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { AxiosResponse } from "axios";
import moment from "moment";
import "../App.css";
import "../styles/Game.css";
import SingleCard from "./SingleCard";
import workPlayLogo from "../assets/workplay-logo.png";
import hourGlass from "../assets/HourGlass.png";

interface Card {
  src: string;
  matched: boolean;
  id: number;
}

const { REACT_APP_LOGO_URL, REACT_APP_API_URL } = process.env;

const cardImages: Card[] = [
  { src: "../assets/MG1.png", matched: false, id: 1 },
  { src: "../assets/MG2.png", matched: false, id: 2 },
  { src: "../assets/MG3.png", matched: false, id: 3 },
  { src: "../assets/MG4.png", matched: false, id: 4 },
  { src: "../assets/MG5.png", matched: false, id: 5 },
  { src: "../assets/MG6.png", matched: false, id: 6 },
  { src: "../assets/MG7.png", matched: false, id: 7 },
  { src: "../assets/MG8.png", matched: false, id: 8 },
];

const imagePaths = cardImages.map((card) => card.src);

const Game: React.FC = () => {
  const [showGame, setShowGame] = useState(false);
  const navigate = useNavigate();
  const [cards, setCards] = useState<Card[]>([]);
  const [moves, setMoves] = useState<number>(0);
  const [choiceOne, setChoiceOne] = useState<Card | null>(null);
  const [choiceTwo, setChoiceTwo] = useState<Card | null>(null);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(45);
  const [matchedCount, setMatchedCount] = useState<number>(0);
  const [result, setResult] = useState<string | null>(null);

  const shuffleCards = () => {
    const shuffledCards: Card[] = [...cardImages, ...cardImages]
      .sort(() => Math.random() - 0.5)
      .map((card) => ({ ...card, id: Math.random() }));
    setChoiceOne(null);
    setChoiceTwo(null);
    setCards(shuffledCards);
    setMoves(0);
    setDisabled(false);
    setMatchedCount(0);
    setTimer(45);
    setResult(null);
    localStorage.clear();
  };

  const handleChoice = (card: Card) => {
    setMoves((prevMoves) => prevMoves + 1);
    choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
  };

  useEffect(() => {
    setTimeout(() => {
      setShowGame(true);
    }, 100);
  }, []);

  useEffect(() => {
    if (choiceOne && choiceTwo) {
      setDisabled(true);

      if (choiceOne.src === choiceTwo.src) {
        setCards((prevCards) => {
          const updatedCards = prevCards.map((card) => {
            if (card.src === choiceOne.src) {
              return { ...card, matched: true };
            }
            return card;
          });
          return updatedCards;
        });
        setMatchedCount((prevCount) => prevCount + 2);
        resetTurn();
      } else {
        setTimeout(resetTurn, 1000);
      }
    }
  }, [choiceOne, choiceTwo]);

  const resetTurn = () => {
    setChoiceOne(null);
    setChoiceTwo(null);
    setDisabled(false);
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prevTimer) => (prevTimer > 0 ? prevTimer - 1 : 0));
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [timer]);

  useEffect(() => {
    if (timer === 0 || matchedCount === cards.length) {
      endGame();
    }
  }, [timer, matchedCount]);

  useEffect(() => {
    const totalMatches = cards.length;
    if (matchedCount === totalMatches) {
      localStorage.setItem("matchMade", (matchedCount / 2).toString());
      localStorage.setItem("result", "won");
    } else if (timer === 0) {
      localStorage.setItem("matchMade", (matchedCount / 2).toString());
      localStorage.setItem("result", "lost");
    }
  }, [matchedCount, cards.length, timer]);

  const endGame = () => {
    const timeTaken = 45 - timer;
    localStorage.setItem("timeTaken", timeTaken.toString());
    localStorage.setItem("moves", moves.toString());
    setDisabled(true);
    setResult("ended");
  };

  useEffect(() => {
    if (result === "ended") {
      const time = localStorage.getItem("timeTaken");
      const movesMade = localStorage.getItem("moves");
      const matchesMade = localStorage.getItem("matchMade");
      const gameResult = localStorage.getItem("result");
      const timestamp = moment().format("YYYY-MM-DD HH:mm:ss");

      if (time && movesMade && matchesMade && gameResult) {
        const gameResultData = {
          timeTaken: time,
          moves: movesMade,
          matchesMade: matchesMade,
          result: gameResult,
          timestamp,
        };

        axios
          .post(`${REACT_APP_API_URL}/memory-result`, gameResultData)
          .then((response: AxiosResponse) => {
            console.log("Response:", response.data.message);

            // Redirect to the result route after posting data
            navigate("/result");
          })
          .catch((error: any) => {
            console.error("Error sending game result:", error);
          });
      } else {
        console.log("Incomplete game data. Data not sent to the backend.");
      }
    }
  }, [result]);

  useEffect(() => {
    shuffleCards();
  }, []);

  // Function to preload images
  const preloadImages = (images: string[]) => {
    images.forEach((imagePath) => {
      const img = new Image();
      img.src = imagePath;
    });
  };

  useEffect(() => {
    preloadImages(imagePaths);
    setTimeout(() => {
      setShowGame(true);
    }, 100);
  }, []);

  return (
    <section>
      <div
        className={`game-container ${
          showGame ? "visible" : ""
        } flex flex-col justify-center gap-y-2 items-center py-12 `}
      >
        <div>
          <a
            href={REACT_APP_LOGO_URL}
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              className="h-35 mb-6 lg:h-10"
              src={workPlayLogo}
              alt="workplay-logo"
            />
          </a>
        </div>

        <div className="grid grid-cols-2 gap-x-6  py-4">
          <div className="flex flex-row justify-center items-center w-20">
            <img src={hourGlass} alt="hour-glass" className="h-8" />
            <h4 className="text-xl">{timer}</h4>
          </div>
          <p className=" text-xl mt-1">Moves: {moves}</p>
        </div>

        <div className="grid grid-cols-4 gap-2 lg:gap-4">
          {cards.map((card) => (
            <SingleCard
              key={card.id}
              card={card}
              handleChoice={handleChoice}
              flipped={card === choiceOne || card === choiceTwo || card.matched}
              disabled={disabled}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default Game;
