import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import poweredByWorkPlay from "../assets/Powered-by-workplay.png";
import "../styles/Result.css";

const { REACT_APP_LOGO_URL, REACT_APP_CONTACT_URL } = process.env;

const Result: React.FC = () => {
  const [showResult, setShowResult] = useState(false);
  const gameResult = localStorage.getItem("result") || "";
  const isWinner = gameResult === "won";

  // Retrieve the values from localStorage
  const timeTaken = localStorage.getItem("timeTaken") || "";
  const matchMade = localStorage.getItem("matchMade") || "";
  const moves = localStorage.getItem("moves") || "";

  const resultSentence = `You've completed the game in ${timeTaken} seconds, making ${matchMade} matches with a total of ${moves} moves.`;

  useEffect(() => {
    setTimeout(() => {
      setShowResult(true);
    }, 100);
  }, []);

  const isZeroMoves = parseInt(moves) === 0;

  return (
    <section>
      <div
        className={`result-container ${
          showResult ? "visible" : ""
        } flex flex-col justify-center items-center gap-y-8 py-16 px-10`}
      >
        <h1 className=" title uppercase text-2xl lg:text-3xl">
          {isWinner ? "You won!" : "Game Over"}
        </h1>
        {!isZeroMoves && (
          <p className="  text-l lg:text-xl text-center w-auto lg:w-96">
            {resultSentence}
          </p>
        )}
        <div className="grid grid-cols-1 gap-y-4">
          <div>
            <Link to="/">
              <button>Try again</button>
            </Link>
          </div>
          <div>
            <a
              href={REACT_APP_CONTACT_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="Btn"
            >
              <button>Contact us</button>
            </a>
          </div>
        </div>
        <a href={REACT_APP_LOGO_URL} target="_blank" rel="noopener noreferrer">
          <img
            src={poweredByWorkPlay}
            alt="Powered By WorkPlay Logo"
            className="h-16 mt-6 lg:h-20"
          />
        </a>
      </div>
    </section>
  );
};

export default Result;
