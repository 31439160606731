import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import "../styles/Home.css";
import workPlayLogo from "../assets/workplay-logo.png";

const { REACT_APP_LOGO_URL } = process.env;

const Home: React.FC = () => {
  const [showHome, setShowHome] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowHome(true);
    }, 100);
  }, []);

  return (
    <section>
      <div
        className={`flex flex-wrap px-8 lg:px-24 py-8 lg:py-24 justify-center lg:justify-start`}
      >
        <div className="flex flex-col justify-center items-center lg:items-start lg:flex-row ">
          <div className="flex flex-col justify-center items-center lg:justify-between lg:items-start">
            <a
              href={REACT_APP_LOGO_URL}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                className="h-35 mb-6 lg:h-10"
                src={workPlayLogo}
                alt="WorkPlay Logo"
              />
            </a>
            <h2 className="title  text-center lg:text-start">Memory Puzzle</h2>
            <p className="description my-6 lg:mb-0  text-center  lg:text-start">
              Two tiles have the same object. Flip the two tiles consecutively
              to uncover them. Uncover all objects in a minute to win.
            </p>
          </div>
          <div className="canvas ">
            {Array(16)
              .fill(null)
              .map((_, index) => (
                <div
                  key={index}
                  className="row h-12 w-12 md:h-16 md:w-16 lg:h-24 lg:w-24 "
                ></div>
              ))}
          </div>
        </div>
        <div>
          <Link to="/game">
            <button className="homeButton">Play</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Home;
