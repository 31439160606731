import React from "react";
import "../styles/SingleCard.css";
import puzzelTile from "../assets/puzzle-tile.png";

interface Card {
  src: string;
  matched: boolean;
  id: number;
}

interface SingleCardProps {
  card: Card;
  handleChoice: (card: Card) => void;
  flipped: boolean;
  disabled: boolean;
}

const SingleCard: React.FC<SingleCardProps> = ({
  card,
  handleChoice,
  flipped,
  disabled,
}) => {
  const handleClick = () => {
    if (!disabled) {
      handleChoice(card);
    }
  };

  return (
    <div className="card">
      <div className={flipped ? "flipped" : ""}>
        <img
          className="front"
          src={card.src}
          alt="card front"
          style={{
            opacity: flipped || card.matched ? 1 : 0,
          }}
        />
        <img
          className="back"
          src={puzzelTile}
          onClick={handleClick}
          alt="card"
        />
      </div>
    </div>
  );
};

export default SingleCard;
